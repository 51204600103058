<template>
  <div style="max-width:1000px;">
    <div class="maintitle">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>运营商
    </div>
    <el-divider></el-divider>
    <el-form ref="form" :model="form" label-width="80px">
      <el-form-item label="姓名">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="form.tel" maxlength="11" minlength="11"></el-input>
      </el-form-item>
      <el-form-item label="状态" class="text-left">
        <el-radio-group v-model="form.status">
          <el-radio-button :label="1">启用</el-radio-button>
          <el-radio-button :label="2">禁用</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="代理地区" class="text-left" v-if="form.status==1">
        <span
            v-for="(item,index) in form.arealist"
            :key="index"
            class="margin-right"
            @click="delarea(item)"
          >{{item.split('-')[1]}}</span>
        
        <!-- <el-checkbox-group v-model="form.arealist">
          <el-checkbox
            v-for="(item,index) in city"
            :key="index"
            :label="item.id+'-'+item.fullname+'-'+JSON.stringify(item.location)"
            :value="item.id"
          >{{item.fullname}}</el-checkbox>
        </el-checkbox-group> -->
        <!-- <div>
          <span
            v-for="(item,index) in form.arealist"
            :key="index"
            class="margin-right"
            @click="delarea(item)"
          >{{item.split('-')[1]}}</span>
        </div> -->
      </el-form-item>
      <!-- <el-form-item v-if="form.uuid != ''" label="所在地区" class="text-left">
        <span>{{form.area_text}}</span>
      </el-form-item> -->
      <el-form-item label="修改地区" class="text-left">
        <el-select v-model="crntcity" placeholder="请选择城市" @change="getAreaList">
          <el-option
            v-for="(item,index) in city"
            :key="index"
            :label="item.fullname"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-checkbox-group v-model="form.arealist">
          <el-checkbox
            v-for="(item,index) in district"
            :key="index"
            :label="item.id+'-'+item.fullname+'-'+JSON.stringify(item.location)"
            :value="item.id"
          >{{item.fullname}}</el-checkbox>
        </el-checkbox-group>
        <div>
          <span
            v-for="(item,index) in form.arealist"
            :key="index"
            class="margin-right"
            @click="delarea(item)"
          >{{item.split('-')[1]}}</span>
        </div>
      </el-form-item>
      
    </el-form>
    <el-button @click="add">
      <span v-if="form.uuid==0">添加</span>
      <span v-else>修改</span>
    </el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        uuid: "", //加盟用户主键
        name: "", //加盟用户姓名
        tel: "", //加盟用户联系电话
        arealist: [], //用户加盟地区列表
        status: 1 //用户状态
      },
      city: [], //地区列表
      district: [], //县、区列表
      crntcity: "" //当前选择地区
    };
  },
  methods: {
    getinfo: function() {
      let id = this.$route.params.id;
      if (!id) {
        return;
      } else {
        this.$axios({
          url: this.HOST + "/jiameng/cityjiameng/getinfo",
          method: "post",
          data: {
            id: id
          }
        }).then(res => {
          if (res.data.status == 1) {
            this.form = res.data.info;
          }
        });
      }
    },
    add: function() {
      this.$axios({
        url: this.HOST + "/jiameng/cityjiameng/add",
        method: "post",
        data: {
          info: this.form
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.$router.push("/jiameng");
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    },
    getJiamengCity() {
      let url = this.HOST + "/jiameng/cityjiameng/getJiamengCity";
      this.$axios({
        method: "post",
        url: url,
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.city = res.data.list;
        } else {
          return [];
        }
      });
    },
    getAreaList(){
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/cityjiameng/getAreaList",
        data: {
          id: this.crntcity
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.district = res.data.list;
        } else {
          return [];
        }
      });
    },
    delarea(str) {
      this.$confirm("确定要删除" + str.split("-")[1] + "吗？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          let i = this.form.arealist.indexOf(str);
          this.form.arealist.splice(i, 1);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    }
  },
  computed: {},
  created: function() {
    this.getinfo();
    this.getJiamengCity();
    // this.getAreaList();
  }
};
</script>

<style>
</style>