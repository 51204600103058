<template>
  <div>
    <div class="maintitle">运营商列表</div>
    <el-divider></el-divider>
    <el-row class="text-left">
        <!-- <el-select v-model="crntdistrict" placeholder="请选择地区" style="width:120px;">
        <el-option
          v-for="(item,index) in district"
          :key="index"
          :label="item.fullname"
          :value="item.id"
        ></el-option>
      </el-select> -->
        <el-button type="success" @click="addjiameng" class="margin-left">添加运营商</el-button>
    </el-row>

    <el-table :data="list" border>
      <el-table-column align="center" fixed prop="name" label="姓名" width="150"></el-table-column>
      <el-table-column align="center" fixed prop="tel" label="手机号" width="150"></el-table-column>
      <el-table-column align="center" prop="area_text" label="代理地区" min-width="200"></el-table-column>
      <el-table-column sortable align="center" prop="logintime" label="最后登录时间" width="200"></el-table-column>
      <el-table-column align="center" prop="lastip" label="最后登录IP" width="200"></el-table-column>
      <el-table-column align="center" prop="status_text" fixed="right" label="状态" width="80"></el-table-column>
      <el-table-column align="center" fixed="right" label="操作" width="240">
        <template slot-scope="scope">
          <el-button-group>
            <el-button size="small" @click="mdfy(scope)">修改</el-button>
            <el-button size="small" @click="resetpassword(scope)">重置密码</el-button>
            <el-button
              size="small"
              @click="$router.push('/jiameng/transaction/'+scope.row.uuid)"
            >查看流水</el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      list: [], //合同列表
    };
  },
  computed: {},
  methods: {
    resetdata:function(){ //更改运营商类型时重置数据
      this.crntcity = '';
      this.crntdistrict = '';
    },
    getlist: function() {
      this.$axios({
        url: this.HOST + "/jiameng/cityjiameng/getJiamengList",
        method: "post",
        data: {}
      }).then(res => {
        if (res.data.status == 1) {
          this.list = res.data.list
        }
      });
    },
    getcommonlist: function() {
      let param = ["type"];
      this.$axios({
        url: this.HOST + "/jiameng/index/getcommonlist",
        method: "post",
        data: { list: param }
      }).then(res => {
        this.dep = res.data.dep;
        this.type = res.data.type;
        this.yewuxianlist = res.data.yewuxian;
      });
    },
    handleSizeChange(size) {
      this.pagesize = size;
      this.page = 1;
      this.getlist();
    },
    handleCurrentChange(p) {
      this.page = p;
      this.getlist();
    },
    srch() {
      this.page = 1;
      this.getlist();
    },
    slctprovince() {
      this.getAreaList(this.crntprovince, "c");
      this.crntcity = "";
      this.crntdistrict = "";
    },
    slctcity() {
      this.getAreaList(this.crntcity, "co");
    },
    getAreaList(id, str) {
      let url = this.HOST + "/jiameng/index/getAreaList";
      this.$axios({
        method: "post",
        url: url,
        data: {
          id: id
        }
      }).then(res => {
        if (res.data.status == 1) {
          switch (str) {
            case "p":
              this.province = res.data.list;
              this.city = [];
              this.district = [];
              this.crntprovince = "";
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "c":
              this.city = res.data.list;
              this.district = [];
              this.crntcity = "";
              this.crntdistrict = "";
              break;
            case "co":
              this.district = res.data.list;
              this.crntdistrict = "";
              break;
          }
        } else {
          return [];
        }
      });
    },
    mdfy(scope) {
      this.$router.push("/jiameng/mdfy/" + scope.row.uuid);
    },
    addjiameng() {
      this.$router.push("/jiameng/add");
    },
    resetpassword(scope) {
      let uuid = scope.row.uuid;
      this.$axios({
        method: "post",
        url: this.HOST + "/jiameng/cityjiameng/resetpassword",
        data: { id: uuid }
      }).then(res => {
        if (res.data.status == 1) {
          this.$alert(
            scope.row.name + "的密码已经修改为" + res.data.info,
            "修改成功"
          );
        } else if (res.data.status == 0) {
          this.$message({
            type: "error",
            message: res.data.info
          });
        }
      });
    },
  },
  created: function() {
    this.getlist();
    // this.getAreaList(0, "p");
  }
};
</script>

<style>
.el-table .cell {
  white-space: pre-line;
}
</style>